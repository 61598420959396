const requireContext = require.context('.', true, /^((?!(index|types)).)*\.js$/)
const modules = {}

requireContext.keys().forEach(item => {
  const module = item.replace(/\.(\/|js)/g, '').split('/')
  const name = module[0]
  const resource = module[1]

  modules[name] = {
    ...modules[name],
    [resource]: requireContext(item).default
  }

  if (!modules[name].namespaced) {
    modules[name].namespaced = true
  }
})

export default modules
