const requireContext = require.context('.', true, /.+\.vue$/)

const components = requireContext.keys().map(item => {
  const { default: component } = requireContext(item)
  const { name } = component

  return [
    name,
    component
  ]
})

const install = Vue => {
  components.forEach(item => {
    const [name, component] = item

    Vue.component(name, component)
  })
}

export default install
